export const themes = {
  inline: 'inline',
  overlay: 'overlay',
} as const;

export const sizes = {
  small: 'small',
} as const;

export type Spinner = {
  size?: (typeof sizes)[keyof typeof sizes];
  theme?: (typeof themes)[keyof typeof themes];
};
